<template>
  <section id="dashboard">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <b-card>
          <b-col>
            <h1 class="text-welcome-header">
              Dashboard
            </h1>
            <h2 class="text-welcome-subheader">
              {{ $t('Superusuario') }}
            </h2>
          </b-col>
        </b-card>
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <data-statistics />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-row class="mb-5">
          <b-col>
            <h1>{{ $t('Buscador') }}</h1>
            <h5 class="text-primary">
              {{ $t('BuscadorContenidos') }}
            </h5>
          </b-col>
        </b-row>

        <keep-alive>
          <component
            :is="'search'"
            :key="$route.fullPath"
          />
        </keep-alive>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'

const DataStatistics = () => import('../DataStatistics.vue')
const Search = () => import('../../../contents/search/search.vue')
export default {
  components: {
    BRow,
    BCol,
    DataStatistics,
    BCard,
    Search,
  },
  data() {
    return {
      roles: null,
      data: {},
    }
  },

  created() {

  },

}

</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
